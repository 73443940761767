import * as React from "react";
import ManualLayout, {ManualLayoutMenu} from "../../components/pages/manual/ManualLayout";
import ManualBody from "../../components/pages/manual/ManualBody";
import Seo from "../../components/seo";
import styled from "@emotion/styled";
import {useEffect, useMemo, useState} from "react";
import jsonRequest from "../../utils/request/jsonRequest";
import {message} from "antd";
import * as queryString from 'query-string'
import Serialize from "../../components/editor/serialize";

const Content = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const ManualDetail = ({location}) => {

  const [loadingMenu, setLoadingMenu] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [menu, setMenu] = useState<ManualLayoutMenu[]>([])
  const [data, setData] = useState<any>([])

  const params = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])

  const selected = useMemo(() => {
    if (menu.length > 0) {
      for (let i = menu.length - 1; i >= 0; i--) {
        if (menu[i].id == data.groupId) {
          return menu[i].id;
        }
      }
    }
    return null;
  }, [data, menu])

  const contentInnerHtml = useMemo(() => {
    let content = [{"type":"paragraph","children":[{"text":data.content ?? ""}]}]
    try {
      content = JSON.parse(data.content)
    } catch {}
    return {__html: content.map(n => Serialize(n)).join('')}
  }, [data])

  const loadMenu = () => {
    if (loadingMenu) {
      return;
    }
    setLoadingMenu(true)
    jsonRequest("/manual/group").then((rsp) => {
      if (rsp.success) {
        setMenu(rsp.data.data.map(item => ({
          id: item.id,
          text: item.name,
          href: `/manual/#${item.id}`
        })))
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingMenu(false)
    })
  }

  useEffect(() => {
    loadMenu()
  }, [])
  useEffect(() => {
    if (loadingData || !params.id) {
      return ;
    }
    setLoadingData(true);
    jsonRequest(`/manual/${params.id}`).then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingData(false)
    })
  }, [params])

  return (<>
    <ManualLayout {...{menu, selected, loading: loadingMenu}}>
      <ManualBody
        title={data?.title ?? ''}
        backLink={`/manual#${selected}`}
        loading={loadingData}
      >
        {/*<Content>*/}
        {/*  {data?.content ?? ''}*/}
        {/*</Content>*/}
        <Content dangerouslySetInnerHTML={contentInnerHtml} />
      </ManualBody>
    </ManualLayout>
  </>)
}

export default ManualDetail;

export const Head = () => <Seo title='GitWork-帮助中心'/>
